main {
  align-content: center;
  box-sizing: border-box;
  display: grid;
  font-family: "SF Pro Text", "SF Pro Icons", "Helvetica Neue", "Helvetica",
    "Arial", sans-serif;
  hyphens: auto;
  line-height: 1.65;
  margin: 0 auto;
  max-width: 680px;
  min-height: 100vh;
  padding: 72px 0;
  text-align: center;
}
h1 {
  font-size: 45px;
}
h2 {
  margin-top: 1.5em;
}
p {
  font-size: 16px;
}
a {
  border-bottom: 1px solid white;
  color: #0076ff;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}
a:hover {
  
}
code,
pre {
  color: #d400ff;
  font-family:
    Menlo,
    Monaco,
    Lucida Console,
    Liberation Mono,
    DejaVu Sans Mono,
    Bitstream Vera Sans Mono,
    Courier New,
    monospace,
    serif;
  font-size: 0.92em;
}
code:before,
code:after {
  content: "\`";
}
.font-grid-black {
  font-family: "grid-black";
}
.topBadgePill {
  margin-top: 5%	
}
.boldText {
  font-weight: bold
}